* {
    box-sizing: border-box;
    /* font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif; */

    font-family: "Source Code Pro", monospace, "Lato", sans-serif;
    font-weight: 300;
}

h1 {
    font-size: 3rem;
    font-weight: 900;
    color: #dedede;
}

h2 {
    font-size: 2rem;
    font-weight: 900;
    color: #dedede;
}

h3 {
    font-size: 1.5rem;
    font-weight: 900;
    color: #dedede;
}

p {
    font-size: 20px;
    margin: 20;
    padding: 20;
    max-width: "1000px";
    letter-spacing: "1.5px";
    line-height: "1.8";
    color: #fafafa;
}

body {
    margin: 0;
    padding: 0;
    background-color: #334359;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
}

.project {
    min-height: 30vh;
    margin-bottom: 40px;
    margin-top: 10px;
    background-color: #3a5a85;
    width: 100%;
}

.link {
    text-decoration: none;
    color: #eaeaea;
    width: 100%;
    height: 100%;
}

#footer {
    width: 100%;
    height: auto;
    padding: 50px;
    margin-bottom: 0px;
    margin-top: 100px;
    box-shadow: 0px 0px 20px 0px #1c1c1c;
    background-color: #2c2c2c;
    text-align: center;
}

.projectH1 {
    font-size: 40;
    margin: 40;
    letter-spacing: 2px;
    text-align: center;
    word-break: break-word;
}
